<template>  
  <section class="hero is-light is-fullheight">
    <div class="hero-head">
      <Navbar />
    </div>
    <div class="hero-body">
      <div class="container content">

        <div class="columns is-centered">
          <div class="column is-8">
            <h1 class="subtitle is-size-3">
              Welcome to Swagible!
            </h1>
            <p>Swagible is a platform where marketers can support and promote their brand through unique merchandise experiences. This updated Privacy Policy applies to users, marketers, and all users of our platform, and is part of our <router-link :to="{ name: 'Terms' }">Terms of Use</router-link>.</p>
            <p>Swagible is a global company. By using our platform, you agree that your personal information that you provide directly to us, or that we collect through your use of the platform, may be transferred to and stored in the United States and handled as described in this Policy.</p>

            <h3 class="subtitle">Information You Provide Through Your Account</h3>
            <p>This is information that you provide to us through text fields, such as your name, payment information and benefits. The information we collect differs depending on if you make an account, become a user, or become a recipient.</p>
            <ul>
              <li>First and Last Name</li>
              <li>Email Address</li>
              <li>Username</li>
              <li>Password</li>
              <li>State and Country of Residence</li>
              <li>Billing / Payment Information</li>
              <li>Emails Addresses of your Recipients</li>
              <li>Local Address</li>
            </ul>

            <p>You may also sign up using a Microsoft, Facebook or Google account. We will ask permission to access basic information from your Microsoft, Facebook or Google account, such as your name, email, and profile picture. You can choose to stop sharing that information with us at any time by going to Microsoft, Facebook or Google to remove Swagible's access to that account and your ability to log in.</p>

            <h3 class="subtitle">Account Holders</h3>
            <p>An account holder is someone who leverages the Swagible platform to promote their brand and create unique merchandise experiences for your recipients or clients.  As an account holder you may provide logos or artwork that you have the rights to use and duplicate.  You must provide your account information and your address information to our payment processors.  You can see the privacy policy for these payments processors on <a href="https://stripe.com/privacy" target="_blank">Stripe</a>.  At this time, Swagible does not receive your full card number.  Payment Processors provide us with a token that represents your account, your card's expiration date, card type and the last four digits of your card number.  If you are required to provide your name and email address to the payment processor, they also provide us with that information.</p>
            <p>We collect and process information about the campaigns you run, the merchandise you hope to send, and the merchandise that is accepted by your Recipients.</p>

            <h3 class="subtitle">Recipients</h3>
            <p>A recipient is someone who receives an invite to participate in a merchandise experience from account holder through Swagible's platform. To become a recipient, an account holder must be sending you merchandise and you will receive an email documenting that you've been asked to participate in a campaign.  You must provide your shipping information, including phone number, so that Swagible or our subsidiaries can ship you the merchandise, or provide updates related to the shipment.  This personal Recipient information is never shared with the person sending merchandise to you (Account Holders), and is only used by Swagible to fulfill the merchandise experience and order.</p>

            <h3 class="subtitle">Additional Information We Collect</h3>
            <label class="label">Automatically Collected Information</label>
            <p>We collect information automatically as you navigate the site or through our third party analytics providers. We may store usage information such as the type of device you use to access Swagible, your operating system, browser type, IP address, and device ID, the pages you visit or request, links clicked, referring sites, user interactions and your search terms. We also derive your location from your self-disclosed country, your IP address, and from your payment card.</p>

            <label class="label">User Surveys</label>
            <p>From time to time, we may solicit your participation in user surveys. We may ask you demographic information, including information related to your gender, ethnicity, race, age, sexual orientation, earnings, and accessibility to better understand and serve our user base which you may elect to share or not. We collect such information to ensure we provide fair and equal access to our services, and to showcase sellers to users for greater discoverability. To the extent that you participate, we will store your survey responses.</p>

            <label class="label">Event Information</label>
            <p>At in-person and online events, we may request information from you. This is done to better tailor the event experience to those in attendance. This information may include your name, email address, payment details, mobile phone number and QR code, demographic information, social media and other online accounts you maintain, details around the types of works you create, and survey or feedback responses.</p>

            <h3 class="subtitle">Information Collected by Third Parties</h3>

            <label class="label">Cookies</label>
            <p>We use Cookies on our website that collect information to allow us and third parties to understand your use of Swagible. Please see our cookie policy for more information on our use of cookies on Swagible.</p>


            <label class="label">How We Use Your Information</label>
            <p>We process your information to:</p>
            <ul>
              <li>verify your identity to comply with US federal, state, and international laws</li>
              <li>allow you to sign in to your account</li>
              <li>process membership payments and payouts</li>
              <li>send merchandise to your shipping address</li>
              <li>send you emails relevant to your usage, as controlled by your email preferences</li>
              <li>reply to your questions</li>
              <li>provide to you existing and enhanced services related to your Swagible account</li>
              <li>market Swagible products and services to you or to audiences similar to you based on your networks and common factors that others have with you, unless you opt out, as controlled by your email preferences</li>
              <li>understand how you use the service, and create better tools for users to serve recipients</li>
              <li>conduct research and development to improve Swagible and develop future products</li>
              <li>prevent fraud and abuse on Swagible</li>
              <li>provide you with reasonable accommodation, if you notify us of a disability</li>
            </ul>

            <h3 class="subtitle">Information We Share with Users</h3>
            <p>By receiving merchandise, you agree to have the following information shared with that user:</p>
            <ul>
              <li>Your acceptance of the merchandise</li>
              <li>The process of the experience in terms of vague process categories (Replied / In transit / Delivered), without sharing any specific particulars like your address or phone number.</li>
              <li>some aggregated and anonymized data about how you use Swagible that cannot be linked back to you or to any individual user</li>
            </ul>

            <h3 class="subtitle">Information We Share with Third Parties</h3>
            <p>We never sell your information to third parties. We will only share data with third parties, other than with sellers, under the following circumstances:</p>
            <ul>
              <li>with your consent, to sellers' service providers, to deliver benefits, such as to ship packages to you.</li>
              <li>with our service providers, who are companies that are contractually engaged with us to provide us with services, such as order fulfillment, email management, analyzing data trends, credit card processing, multi currency settlement solutions,  increasing our brand awareness and user engagement with marketing initiatives, and fraud detection and prevention. These companies may have access to your data to perform their services, and are obligated by contract to safeguard any of your data they receive from us to the same extent that Swagible protects it.</li>
              <li>to protect the security or integrity of Swagible, and to protect the rights, property, or safety of Swagible, its employees, users, or others, if we believe that disclosure is reasonably necessary to comply with a law, regulation, or other valid legal process (e.g., subpoenas or warrants served on Swagible). If we are going to release your data, we will do our best to provide you promptly with notice by email, unless we are prohibited by law from doing so.</li>
              <li>in connection with the sale, merger, bankruptcy, sale of assets or reorganization of our company. We will notify you if a different company receives your data. The promises in this privacy policy apply to any data transferred to a new entity.</li>
            </ul>


            <h3 class="subtitle">Information Swagible Collects from Third Party Apps</h3>
            <p>When you create a Swagible account, you can elect to connect your social account(s) (e.g. Twitter, Facebook, Google, YouTube, Twitch) with Swagible, and we will collect and store some social information from those platforms, such as:</p>
            <ul>
              <li>follower or subscriber counts</li>
              <li>post or upload counts</li>
              <li>view, like, and comment counts</li>
            </ul>

            <p>This social information allows us to provide you a better Swagible experience, and also helps guide future development of Swagible. We use this data to:</p>
            <ul>
              <li>help sellers and users find each other on Swagible</li>
              <li>assess how to make sellers more successful on Swagible</li>
              <li>analyze and describe our business</li>
            </ul>

            <h3 class="subtitle">Information We Share with the Public</h3>
            <p>The following information is publicly accessible:</p>
            <ul>
              <li>number of users</li>
            </ul>

            <h3 class="subtitle">Your Preferences and Rights over Data</h3>
            <label class="label">Choosing Your Preferences</label>
            <p>The Settings link is located by clicking on your avatar or profile at the top right hand of your screen, after your log into your Swagible account. Settings lets you see your account preferences. You can see and adjust your settings by viewing your preferences and, if you wish, by changing your selections.</p>

            <label class="label">Marketing Activities</label>
            <p>By agreeing to our terms of use, you expressly agree that Swagible may:</p>

            <ul>
              <li>provide you with information about your service, service enhancements, or new Swagible products, while you are on our web site or using our apps.</li>
              <li>send you messages regarding your existing service, or enhancements related to your existing service, when you are off our platform, via email, or via text, if you have signed up for a service or event notification that uses text messages.</li>
              <li>send you messages related to services we provide which are separate from your existing service via email, from which you may opt out.</li>
              <li>market Swagible to you and audiences similar to you based on your networks and common factors  that others have with you, unless you opt out.</li>
              <li>send you marketing emails or texts if you don't have a Swagible account but have consented to receiving such messages, from which you may opt out.</li>
              <li>ask for demographic information to spotlight and celebrate sellers.</li>
              <li>promote your account for greater discoverability.</li>
              <li>ship physical items to you via mail as part of a promotional activity.</li>
            </ul>

            <label class="label">Opting out of Marketing</label>
            <p>You may object to the use of your data for marketing purposes at any time, free of charge, when we collect your personal information and in every marketing communication. You may also object to the use of your data for marketing upon creation of your account or anytime thereafter, by opting out <a href="https://Swagible.com/opt-out" target="_blank">here</a>.</p>
            <p>Opting out will stop marketing emails. Please allow up to 30 days for your opt-out request to be processed.  If you have an account with Swagible, you will continue to receive service-related emails, and texts, if you have opted into receiving texts. You will also continue to receive service-related shipments of benefits to the designated delivery address.</p>
            <p>We will never sell your information to third parties for marketing purposes without your prior consent.</p>

            <label class="label">Accessing, Updating or Exporting the Information in Your Account</label>
            <p>You can access and update certain information associated with your account on the settings pages. Additionally, you can delete your account, or export your personal information, by going to <a href="privacy.Swagible.com" target="_blank">privacy.Swagible.com</a>.</p>

            <label class="label">Deleting Your Account</label>
            <p>You may self-initiate a deletion of your account directly at <a href="privacy.Swagible.com" target="_blank">privacy.Swagible.com</a>, clicking on the Take Control button, and submitting an “Erase” request. Please be aware that this is a final act and cannot be reversed. If you would like to transport your data, please be sure to download your data first, before you delete your account.</p>

            <label class="label">Turning off Email Notifications</label>
            <p>You can change your email notifications in your email settings. While this allows you to stop many notification emails, we will still send some critical service emails.</p>

            <label class="label">Verification of Requests</label>
            <p>Users maintain password protected accounts with Swagible. If you submit a request to exercise your privacy rights, you will be asked to verify the request by logging into your Swagible account.</p>
            <p>If we are unable to verify your request, then for your protection, we reserve the right to deny the request.</p>


            <h3 class="subtitle">Compliance with EU Privacy Laws and Privacy Shield</h3>
            <p>Swagible is an international company that complies with the General Data Protection Regulation (GDPR). We act as Data Controllers, as defined under the GDPR, and process personal data based on our legitimate interests, and for contractual necessity in providing you the Service as described in “How We Use Your Information.” We  also process and share personal information based on notification and users' consent, which our users may revoke at any time.</p>
            <p>Swagible relies on various legal bases to lawfully execute international transfers of personal information, including the European Union Commission approved standard contract clauses.</p>
            <p>Swagible complies with the EU-U.S. Privacy Shield Framework and Swiss-U.S. Privacy Shield Framework as set forth by the U.S. Department of Commerce regarding the collection, use, and retention of personal information transferred from the European Union and Switzerland to the United States. Swagible has certified to the Department of Commerce that it adheres to the Privacy Shield Principles.  If there is any conflict between the terms in this privacy policy and the Privacy Shield Principles, the Privacy Shield Principles shall govern.  To learn more about the Privacy Shield program, and to view our certification, please visit <a href="https://www.dataprivacyframework.gov/s/" target="_blank">Digital Privacy Framework</a>.</p>
            <p>Under limited circumstances, you may invoke binding arbitration before the Privacy Shield Panel. More Information about this arbitration process can be found at <a href="https://www.dataprivacyframework.gov/s/" target="_blank">Digital Privacy Framework</a>.</p>
            <p>Swagible is responsible for the processing of personal information it receives or subsequently transfers to a third party acting as an agent on Swagible's behalf. Swagible complies with the Privacy Shield Principles for all onward transfers of personal information, including the onward transfer liability provisions.</p>
            <p>With respect to personal information received or transferred pursuant to the Privacy Shield Frameworks, Swagible is subject to the regulatory enforcement powers of the U.S. Federal Trade Commission.</p>

            <h3 class="subtitle">Compliance with Delaware Privacy Laws</h3>
            <p>Swagible's headquarters are located in Delaware, USA, and we are subject to the Delaware Online Privacy and Protection Act.</p>
            <p>Swagible provides Delaware residents, and all users, the right to view, access, or transport their information at the Swagible Privacy Center. All users also have the right to submit a request for deletion of information by going to the Swagible Privacy Center, clicking on the “Take Control” button, and requesting “Erase.”</p>

            <h3 class="subtitle">Exercising Your Data Rights</h3>
            <p>Users in certain locations may have certain rights under the General Data Protection Regulation (GDPR) or under the California Consumer Privacy Act (CCPA) regarding data that Swagible controls as a Data Controller as defined under the GDPR, including:</p>
            <ul>
              <li>the right of access to their personal data,</li>
              <li>the right to correct or rectify any inaccurate personal data,</li>
              <li>the right to restrict or oppose the processing of personal data,</li>
              <li>the right to erase or delete their personal data,</li>
              <li>the right to personal data portability, and</li>
              <li>the right to opt-out of the sale of personal information.</li>
            </ul>

            <p>You can exercise rights over your data on Swagible in the following ways:</p>

            <ul>
              <li>accessing, reviewing, modifying, and updating your data by logging into your account at Swagible and going to your account settings.</li>
              <li>viewing our privacy policy or viewing our data practices by going online to our Swagible Privacy Center, where the Data Practices tab describes how we use your data, and the Policies tab shows you our privacy policy.</li>
              <li>downloading your data to port it, or deleting your data altogether, by clicking on the “Take Control” button at the Swagible Privacy Center.</li>
              <li>deleting a previously-disabled account by emailing Swagible at <a href="mailto:privacy@Swagible.com">privacy@Swagible.com</a>.</li>
              <li>opting out of marketing by entering your email address at this opt out link.</li>
              <li>going to <router-link :to="{ name: 'Faqs' }">FAQ support</router-link> online for data-related issues.</li>
            </ul>

            <p>If you are unable to log into your account, and are unable to recover your account with a password reset in order to lodge your privacy request, then you may reach out to <a href="mailto:privacy@Swagible.com">privacy@Swagible.com</a>. We reserve the right to decline you access to or recovery of your account, at our discretion, to prevent an unauthorized takeover of your account.</p>

            <h3 class="subtitle">Account Holders with Disabilities</h3>
            <p>Swagible is committed to accessibility for its account holders with disabilities. If you are having difficulty accessing your account, please email us at <a href="mailto:accessibility@Swagible.com">accessibility@Swagible.com</a> for assistance.</p>

            <h3 class="subtitle">Contacting our Data Protection Officers</h3>
            <p>If you have privacy concerns or questions, you can contact our European or US Data Protection Officers (DPOs) via email at <a href="mailto:privacy@Swagible.com">privacy@Swagible.com</a>.</p>
            <p>You may also mail a letter to the DPO with your questions.</p>
            <ul>
              <li>Mail to “Swagible Privacy Team, 9450 SW Gemini Dr PMB 86139, Beaverton, Oregon 97008-7105 US”</li>
            </ul>

            <h3 class="subtitle">Establishing an Authorized Agent</h3>
            <p>You may designate an authorized agent to make a request on your behalf. In order to designate an authorized agent to make a request on your behalf, you must provide a valid power of attorney, the requester's valid government ID, and the authorized agent's valid government ID. Please contact <a href="mailto:privacy@Swagible.com">privacy@Swagible.com</a> with your request. To protect your information from account takeover, we may decline access to or recovery of your account at our discretion.</p>

            <h3 class="subtitle">Prohibition against Discrimination</h3>
            <p>We do not discriminate against users who exercise their privacy rights.</p>

            <h3 class="subtitle">Our Data Retention Period</h3>
            <p>We retain your account information for ten years after your account is last active, unless you delete, or you request us to delete, your account. We may continue to retain some information, even after you delete your account if we are required to do so in order to comply with various laws.</p>

            <h3 class="subtitle">Security</h3>
            <p>The security of your personal information is important to us and we follow industry standards to protect it.</p>

            <h3 class="subtitle">Children</h3>
            <p>Swagible is not directed at children under the age of 18, and children may not create an account or otherwise use Swagible.</p>

            <h3 class="subtitle">Changes</h3>
            <p>We may sometimes make changes to this policy. If we make material changes that adversely affect your rights under this policy, we will let you know by posting an announcement on the site or sending you an email in advance of the changes coming into effect. Continuing to use Swagible after a change to this policy means you accept the new policy. If you have any questions, please email <a href="mailto:privacy@Swagible.com">privacy@Swagible.com</a>.</p>
            <p>Effective immediately for existing users and users joining Swagible on or after July 1, 2022.</p>
          </div>
        </div>

      </div>
    </div>
    <div class="hero-foot">
      <Footer />
    </div>
  </section>
</template>

<script>
import { ref } from 'vue'
import Navbar from '@/components/Navbar1.vue'
import Footer from '@/components/Footer.vue'

export default {
  components: { Navbar, Footer },
  setup() {
    const email = ref('')
    const name = ref('')
    const message = ref('')

    const handleSubmit = async () => {
      alert('handleSubmit')
    }

    return {
      email,
      name,
      message,

      handleSubmit,
    }
  }
}
</script>

<style scoped>
.label {
  font-weight: 600;
}

a {
  color: var(--info) !important;
  text-decoration: underline;
}
</style>